import React from "react";
import { Link } from "gatsby";

export default ({ data }) => {    
  let categories = data.category && data.category.map(x => x.title).join(", ");
  return (
    <Link to={`/integration/${data.linkTo ? data.linkTo.slug : "#"}`} className="shadow-box integration w-inline-block">
      <div
        className="integration-color"
        style={{
          backgroundColor: data.integrationColor.hex,
          backgroundImage: `url(${data.integrationLogo.url})`,
          backgroundPosition: "50% 50%",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat"
        }}
      />
      <div className="platform-detail integration">
        <h6 className="h6">{data.integrationName}</h6>
        <div className="tiny-copy right">{ categories || "" }</div>
      </div>
      {data.showCsvLabel && <div className={`data-csv-support ${data.csvLabelTheme === "Dark"? "revert": ""}`}></div>}
    </Link>
  );
};
