import React from "react";
import { graphql } from 'gatsby'
import { resetWebflow } from "../utils/common";
import IntegrationBox from "../components/integration/integration-box";
import IntegrationBoxHolder from "../components/blog/blogPreviewPlaceHolder";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import ReactMarkdown from "react-markdown";
import Layout from "../layouts";

export default ({
  data: {
    allDatoCmsIntegrationCategory: { edges: categories },
    datoCmsIntegration: {
      pageTitle,
      pageDescription,
      csvSectionTitle,
      csvSectionDescription,
      universalConnectorSubDescription,
      integrationPlatform: platforms,
      seoMetaTags,
      notificationPage,
    },
    allDatoCmsNotification: { edges: notifications },
  },
}) => {
  resetWebflow("5aabd62c51e22e4f80346d1c");
  const csvPlatforms = platforms.filter(x => x.showCsvLabel);  
  const noCSVPlatforms = platforms.filter(x => !x.showCsvLabel);
     
  let sisense = noCSVPlatforms.find(x => x.integrationName === "Sisense");
  if (sisense) {
    sisense.linkTo = {
      slug: "sisense"
    }
  }

  return (
    <Layout>
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <HelmetDatoCms seo={seoMetaTags} />
      <header className="main-header about reducedbottom">
        <div className="integration-container">
          <h1 className="h-fixedwidth">{pageTitle}</h1>
          <div className="sm-spacing" />
          <div className="p center-align">{pageDescription}</div>
          <div className="md-spacing" />
          <div className="grid-container-integration">
            {noCSVPlatforms.map((platform) => (
              <div key={`all${platform.id}`}>
                <IntegrationBox data={platform} />
              </div>
            ))}
            <IntegrationBoxHolder />
          </div>

          <div className="md-spacing" />
          <h1 className="h-fixedwidth">{csvSectionTitle}</h1>
          <div className="sm-spacing" />
          <div className="p center-align">
            <ReactMarkdown source={csvSectionDescription} escapeHtml={false} />
          </div>
          <div className="md-spacing" />
          <div className="grid-container-integration">
            {csvPlatforms.map((platform) => (
              <div key={`all${platform.id}`}>
                <IntegrationBox data={platform} />
              </div>
            ))}
            <IntegrationBoxHolder />
          </div>
          {universalConnectorSubDescription && <React.Fragment>
            <div class="md-spacing"></div>
            <div class="content-center">
              <div class="small-copy">
                <ReactMarkdown source={universalConnectorSubDescription} escapeHtml={false} />
              </div>
            </div>
          </React.Fragment>}
        </div>
      </header>
    </Layout>
  );
};

export const query = graphql`
  query IntegrationQuery {
    allDatoCmsIntegrationCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    datoCmsIntegration {
      pageTitle
      csvSectionTitle
      csvSectionDescription
      universalConnectorSubDescription
      pageDescription
      notificationPage {
        id
        page
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      integrationPlatform {
        ... on DatoCmsIntegrationBlock {
          id
          integrationName
          integrationLogo {
            url
          }
          integrationColor {
            hex
          }
          category {
            id
            title
          }
          linkTo {
            ... on DatoCmsIntegrationDetails2 {
              id
              slug
              pageTitle              
            }
            ... on DatoCmsIntegrationSuperPremium {
              id
              slug
              pageTitle
            }
          }
          showCsvLabel
          csvLabelTheme
          model {
            apiKey
          }
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
